<template>
  <v-card>
    <v-card-title>
      <v-row>
        <v-col>
          <v-banner
            color="light-blue lighten-5 mb-2 milestones-awareness"
            single-line
          >
            <v-icon slot="icon" color="primary" size="36">
              mdi-check-decagram
            </v-icon>
            <h5 class="ml-2">
              Data is not real-time, it will be updated regularly
            </h5>
          </v-banner>
        </v-col>
      </v-row>
      <v-form ref="trophiesForm" :disabled="loading">
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              label="CATEGORIES"
              :items="milestones"
              item-text="name"
              item-value="category"
              v-model="category"
              :open-on-clear="false"
              hide-details
              outlined
              :dense="!mobile"
              clearable
              @change="resetPagination"
              :rules="[v => !!v || 'Required']"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              label="MILESTONES"
              :items="trophies"
              item-text="name"
              item-value="code"
              v-model="trophy"
              :open-on-clear="false"
              hide-details
              outlined
              :dense="!mobile"
              clearable
              @change="resetPagination"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <auto-complete-users
              label="USER"
              v-model="user"
              @change="resetPagination"
              searchable
              @search="recalculateMilestones"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              label="ROLES"
              :items="roles"
              item-text="name"
              item-value="code"
              v-model="role"
              hide-details
              :open-on-clear="false"
              outlined
              :dense="!mobile"
              clearable
              @change="resetPagination"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-autocomplete
              label="LOCATIONS"
              :items="locations"
              v-model="location"
              hide-details
              :open-on-clear="false"
              outlined
              :dense="!mobile"
              clearable
              @change="resetPagination"
              :search-input.sync="searchLocations"
            />
          </v-col>
          <v-col cols="12" sm="6" md="2">
            <v-btn
              color="primary"
              block
              @click="getData"
              :disabled="loading || exporting"
              :x-large="mobile"
            >
              Submit
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" md="2" v-if="items.length">
            <v-spacer />
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                  :loading="loading || exporting"
                  :x-large="mobile"
                  block
                >
                  Export
                  <v-icon right dark>mdi-menu-down</v-icon>
                </v-btn>
              </template>
              <v-list class="export-list">
                <v-list-item
                  :key="'csv'"
                  class="dropdown-item"
                  @click="exportFile('csv')"
                  :dense="!mobile"
                >
                  <v-list-item-title>
                    <v-icon left>mdi-file-delimited-outline</v-icon>
                    CSV
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :key="'xlsx'"
                  class="dropdown-item"
                  @click="exportFile('xlsx')"
                  :dense="!mobile"
                >
                  <v-list-item-title>
                    <v-icon left>mdi-file-excel-outline</v-icon>
                    Excel
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <date-picker
              v-model="date[0]"
              label="START DATE"
              @clear="date = [null, null]"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <date-picker
              v-model="date[1]"
              label="END DATE"
              :minDate="date[0]"
              :disable="!date[0]"
            />
          </v-col>
          <v-col cols="12" sm="6" md="4" class="pa-0 pl-3">
            <v-checkbox v-model="youth" label="Youth Anglers"></v-checkbox>
          </v-col>
        </v-row>
      </v-form>
    </v-card-title>
    <v-card-text class="mt-5">
      <v-data-table
        :headers="headers"
        :items="items"
        :items-per-page="5"
        :loading="loading"
        :options.sync="options"
        :server-items-length="totalDocs"
        :footer-props="footerOpts"
        class="elevation-1"
        :hide-default-footer="!items.length"
        hide-default-header
      >
        <template v-slot:[`item.icon`]="{}">
          <v-icon>mdi-trophy</v-icon>
        </template>
        <template v-slot:[`item.record.createdDate`]="{ item }">
          <span>{{ formatDate(item.record.createdDate) }}</span>
        </template>
        <template v-slot:[`item.category`]="{ item }">
          <span>{{ item.category | uppercase }}</span>
        </template>
        <template v-slot:header="{ props }" v-if="items.length">
          <th
            class="text-left pl-5 header_size pt-5"
            v-for="(head, i) in props.headers"
            :key="i"
          >
            <div class="d-flex head_div">
              <v-btn icon v-if="!!head['sort']" @click="sortBy(head)" x-small>
                <v-icon v-if="head['order']">mdi-arrow-down</v-icon>
                <v-icon v-else>mdi-arrow-up</v-icon>
              </v-btn>
              <span
                class="caption font-weight-bold grey--text text--darken-2 pl-1"
              >
                {{ head.text }}
              </span>
            </div>
          </th>
        </template>
      </v-data-table>
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="loading && items.length"
        striped
        height="5"
      ></v-progress-linear>
    </v-card-text>
  </v-card>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import { MILESTONES, MILESTONES_ROLES } from '../../helpers/constants';
import { fixTimezone, formatDate, getFile } from '../../helpers/format';
import { notifyError, notifyMessage } from '../../helpers/notifications';
import AutoCompleteUsers from '../../components/appComponents/AutoCompleteUsers.vue';
import DatePicker from '../../components/appComponents/DatePicker.vue';

export default {
  components: { AutoCompleteUsers, DatePicker },
  name: 'find-trophies',
  data: () => ({
    milestones: MILESTONES,
    roles: MILESTONES_ROLES,
    category: 'tag',
    trophy: null,
    role: null,
    year: null,
    loading: false,
    rule: [v => !!v || 'Required'],
    items: [],
    options: {},
    totalDocs: 0,
    footerOpts: {
      pageCount: 0,
      showFirstLastPage: true,
      'items-per-page-options': [30, 50, 90]
    },
    exporting: false,
    user: null,
    headers: HEADERS,
    locations: [],
    location: null,
    search: null,
    date: [null, null],
    youth: false,
    sort: null,
    order: false
  }),
  computed: {
    years() {
      const years = [];
      for (let year = 2012; year <= moment().year(); year++) {
        years.push(year);
      }
      return years.reverse();
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    trophies() {
      return (
        this.milestones.find(milestone => milestone.category === this.category)
          ?.trophies || []
      );
    },
    searchLocations: {
      get: function() {
        return this.search;
      },
      set: function(value) {
        if (value !== this.search) {
          this.search = value;
          value ? this.getLocationList(value) : (this.locations = []);
        }
      }
    }
  },
  watch: {
    options: {
      handler: 'trackPagination'
    }
  },
  filters: {
    uppercase(value) {
      if (!value) {
        return '';
      }

      return String(value).toUpperCase();
    }
  },
  methods: {
    ...mapActions('query', [
      'getTrophies',
      'getTrophiesLocations',
      'recalculateMilestonesByUser'
    ]),
    async getData() {
      const valid =
        this.$refs.trophiesForm && this.$refs.trophiesForm.validate();
      if (valid) {
        this.loading = true;
        const { page, itemsPerPage } = this.options;
        const { _id, id } = this.user || {};
        let payload = {
          trophy: this.trophy,
          category: this.category,
          userId: _id || id,
          role: this.role?.toUpperCase(),
          location: this.location,
          date: this.date,
          youth: this.youth,
          page: page || 1,
          limit: itemsPerPage || 10,
          sort: this.sort,
          order: this.order ? 1 : 2
        };

        if (this.year) {
          payload = { ...payload, year: this.year };
        }
        const response = await this.getTrophies(payload);
        const { docs, totalDocs } = response?.data || {};
        this.items = docs || [];
        this.totalDocs = totalDocs || 0;
        this.loading = false;
      }
    },
    async getLocationList(pattern) {
      try {
        const response = await this.getTrophiesLocations({ pattern });
        const locations = response?.data || {};

        if (!locations) {
          this.locations = [];
          return;
        }
        this.locations = locations.map(location => location._id);
      } catch {
        this.locations = [];
      }
    },
    async trackPagination() {
      if (this.items.length) {
        await this.getData();
      }
    },
    formatDate(value) {
      const fixedDate = fixTimezone(value);
      return formatDate(fixedDate);
    },
    resetPagination() {
      if (this.options?.page !== 1) {
        this.options = { ...this.options, page: 1 };
      }
    },
    async exportFile(format) {
      this.exporting = true;

      const { page, itemsPerPage } = this.options;
      const { _id, id } = this.user || {};
      let payload = {
        trophy: this.trophy,
        category: this.category,
        userId: _id || id,
        role: this.role?.toUpperCase(),
        location: this.location,
        date: this.date,
        youth: this.youth,
        format,
        page: page || 1,
        limit: itemsPerPage || 10
      };

      if (this.year) {
        payload = { ...payload, year: this.year };
      }
      const response = await this.getTrophies(payload);

      const { file } = response?.data || {};

      if (!file) {
        notifyError(
          "Something went wrong. The data can't be exported or there is no data available to download"
        );
        this.exporting = false;
      }

      let name = 'Milestones';

      if (this.category && this.trophy) {
        name = `${this.category.toUpperCase()}_${this.trophy.toUpperCase()}`;
      }

      if (this.category && !this.trophy) {
        name = `${name}_${this.category.toUpperCase()}`;
      }

      if (!this.category && this.trophy) {
        name = `${name}_${this.trophy.toUpperCase()}`;
      }

      getFile(file, format, name);
      this.exporting = false;
    },
    async recalculateMilestones() {
      this.loading = true;
      const { _id, id } = this.user || {};
      const response = await this.recalculateMilestonesByUser({
        userId: _id || id
      });
      this.loading = false;
      const { success } = response?.data || {};

      if (!success) {
        notifyError(
          'The system was unable to recalculate milestones for the selected user'
        );
        return;
      }

      notifyMessage(
        'Milestones successfully recalculated for the selected user'
      );
      await this.getData();
    },
    async sortBy(head) {
      this.headers.forEach(item => {
        if (item.sort === head?.sort) {
          item.order = !head?.order;
        } else {
          item.order = true;
        }
      });
      const header = this.headers.find(item => item?.sort === head?.sort);
      this.sort = header.sort;
      this.order = header.order;
      await this.getData();
    }
  }
};

const HEADERS = [
  {
    value: 'icon'
  },
  {
    text: 'CATEGORY',
    align: 'start',
    sortable: false,
    value: 'category'
  },
  {
    text: 'HIT',
    align: 'start',
    sortable: false,
    value: 'hit'
  },
  {
    text: 'NAME',
    align: 'start',
    sortable: false,
    value: 'user.name',
    sort: 'name',
    order: true
  },
  {
    text: 'EMAIL',
    align: 'start',
    sortable: false,
    value: 'user.email'
  },
  {
    text: 'ROLE',
    align: 'start',
    sortable: false,
    value: 'record.role'
  },
  {
    text: 'EVENT DATE',
    align: 'center',
    sortable: false,
    value: 'record.createdDate',
    sort: 'date',
    order: true
  },
  // {
  //   text: 'SYSTEM DATE',
  //   align: 'center',
  //   sortable: false,
  //   value: 'record.enteredDate'
  // },
  {
    text: 'LOCATION',
    align: 'start',
    sortable: false,
    value: 'record.location'
  }
];
</script>

<style>
.milestones-awareness div[class~='v-banner__icon'] {
  display: contents;
}
</style>
